import { useState } from 'react';
import { useEffect } from 'react';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';
import { MuiChipsInput } from 'components/ChipsInput';
import * as z from 'zod';
import { updateMonitorFilter } from 'domain/monitor';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import InputAdornment from '@mui/material/InputAdornment';
import { BED_COUNTS } from 'shared/constants';
import { useReadLocationTipsQuery } from 'services/location';
import { Unstable_NumberInput as NumberInput } from '@mui/base/Unstable_NumberInput';
import {
    Box,
    FormGroup,
    FormControl,
    InputLabel,
    Button,
    TextField,
    Typography,
    Stack,
    MenuItem,
    Checkbox,
    FormLabel,
    Select,
    OutlinedInput
} from '@mui/material';
import type { Monitor } from 'shared/types';
import type { MonitorFilterItem } from 'shared/types';
import type { BedSelectItem } from 'shared/types';

const FormSchema = z.object({
    title: z
        .string()
        .min(1),
    locations: z
        .array(z.string())
        .nonempty(),
    beds:
        z.number(),
    priceForComparison: z
        .number()
}).passthrough();

export type CompetitorCalendarCreateSimpleProps = {
    defaultValue?: Monitor,
    onSubmit?: Function
};

export const CompetitorCalendarCreateSimple = ({ defaultValue, onSubmit }: CompetitorCalendarCreateSimpleProps): JSX.Element => {
    const {
        handleSubmit,
        formState: { errors, submitCount, isSubmitting },
        control,
        getValues,
        reset,
        trigger
    } = useForm<Monitor>({
        resolver: zodResolver(FormSchema),
        defaultValues: defaultValue,
    });

    const handleSubmitForm = (value: Monitor, event: any) => {
        event.preventDefault();

        if (typeof onSubmit === "function") {
            onSubmit({
                event: event,
                value: value
            });
        }
    }

    const recalculateMonitor = async (monitor: Monitor, name: string, value: any): Promise<Monitor> => {
        const monitorFilterItem: MonitorFilterItem = {
            name: name,
            value: value
        };


        const newMonitor: Monitor = updateMonitorFilter({ monitor: monitor, monitorFilterItem });
        if (newMonitor.locations && newMonitor.locations.length > 0) {
            newMonitor.title = `${newMonitor.locations.join(' ')}`;
        }

        return newMonitor;
    } 

    const validateFields = () => {
        if (submitCount > 0) {
            trigger();
        }
    }

    const updateMonitorDataOnForm = (monitor: Monitor) => {
        reset(monitor, { keepDirtyValues: true, keepSubmitCount: true })
    }

    const {
        data: readLocationTipsData,
        isLoading: readLocationTipsDataIsLoading
    } = useReadLocationTipsQuery(undefined);

    return (
        <>
            <Box
                sx={{
                    display: 'Grid',
                    rowGap: '0px'
                }}
                component="form"
                onSubmit={handleSubmit(handleSubmitForm)}
            >
                <Typography variant="h4">Let's find your competitors now!</Typography>
                <Controller
                    control={control}
                    name="locations"
                    render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState: { dirtyFields },
                    }) => (
                        <FormControl required>
                            <h4>Enter Location to Spy:</h4>
                            <MuiChipsInput
                                autoCompleteOptions={readLocationTipsData || []}
                                helperText={errors['locations'] ? errors['locations'].message : ''}
                                error={!!errors['locations']}
                                clearInputOnBlur={true}
                                value={value}
                                name={name}
                                onChange={
                                    async (value) => {
                                        const newMonitor: Monitor = await recalculateMonitor(getValues(), name, value);
                                        updateMonitorDataOnForm(newMonitor);
                                        validateFields();
                                    }}
                            />
                        </FormControl>
                    )}
                />  
                <Controller
                    control={control}
                    name="beds"
                    render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState: { dirtyFields },
                    }) => (
                        <FormControl required sx={{ width: 300, margin: '0 0 0 0' }}>
                            <h4>Enter Number of Beds:</h4>
                            <Select
                                labelId="beds-label"
                                id="beds-selector"
                                name={name}
                                value={value}
                                onChange={
                                    async (event) => {
                                        const newMonitor: Monitor = await recalculateMonitor(getValues(), name, event.target.value);
                                        updateMonitorDataOnForm(newMonitor);
                                        validateFields();
                                    }
                                }
                                input={<OutlinedInput  />}
                            >
                                {BED_COUNTS.map((obj: BedSelectItem) => (
                                    <MenuItem key={obj.value} value={obj.value}>
                                        {obj.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                />
                <Controller
                    name="priceForComparison"
                    control={control}
                    render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState: { dirtyFields },
                    }) => (
                        <FormControl required>
                            <h4>Enter Total Price You Charge (including cleaning fee):</h4>
                            <TextField
                                sx={{ mb: 2 }}
                                InputProps={{
                                    startAdornment: "$",
                                    inputProps: { min: 0 },
                                    endAdornment: <InputAdornment position="end">/per day</InputAdornment>
                                }}
                                type="number"
                                fullWidth
                                error={!!errors['priceForComparison']}
                                helperText={errors['priceForComparison'] ? errors['priceForComparison'].message : ''}
                                value={value}
                                name={name}
                                onChange={
                                    async (event) => {
                                        const newMonitor: Monitor = await recalculateMonitor(getValues(), name, event.target.value)
                                        updateMonitorDataOnForm(newMonitor);
                                        validateFields();
                                    }
                                }
                            />
                        </FormControl>
                    )}
                />
                <FormControl sx={{marginTop: '40px'}}>
                    <Stack direction="row" spacing={2}>
                        <Button type="submit" variant="contained" color="success">Go</Button>
                    </Stack>
                </FormControl>
            </Box>
        </>
    )
}