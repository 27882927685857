import { ReadMonitorRunResultParams, useReadMonitorRunResultGroupedByMonitorIdQuery } from 'services/monitorRunResult';
import { useState } from 'react';
import { useEffect } from 'react';
import { Calendar } from 'components/Calendar';
import { DayDialog } from './components/DayDialog';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { MonitorRunsResultDetailCard } from 'shared/types';
import { useReadUserSettingsQuery } from 'services/user';
import type { UserSettings } from 'shared/types'
import { createBlankUserSettings } from 'domain/userSettings';
import { InvitationToInstallApplication  } from './../../components/InvitationToInstallApplication';
import { Box } from '@mui/material';

type ParametersListCalendarProps = {
    monitorId: number,
    selectedMonitorLaunchTask: any,
    monitorRunsResultDetailCard?: MonitorRunsResultDetailCard,
    onSelectedMonitorLaunchTaskChange?: Function
}

export const ParametersListCalendar = (props: ParametersListCalendarProps): JSX.Element => {
    const {
        monitorId,
        monitorRunsResultDetailCard,
        selectedMonitorLaunchTask,
        onSelectedMonitorLaunchTaskChange
    } = props;

    const [skip, setSkip] = useState<boolean>(true);
    const [showLoader, setShowLoader] = useState<boolean>(true);
    const [selectedDayData, setSelectedDayData] = useState<any>();
    const [openDayDialog, setOpenDayDialog] = useState<boolean>(false);
    const [userSettings, setUserSettings] = useState<UserSettings | undefined>(undefined)
    const [hasUserSettings, setHasUserSettings] = useState<boolean>(false)
    const [selectedMonitorLaunchTasksId, setSelectedMonitorLaunchTasksId] = useState<Array<number>>([]);
    const [selectedDaysElements, setSelectedDaysElements] = useState<Array<any>>([]);

    const readMonitorRunResultByMonitorIdQueryParams: ReadMonitorRunResultParams = {
        monitorId: monitorId,
    };

    const readMonitorRunResultByMonitorIdQueryOptions = {
        skip: skip,
        refetchOnMountOrArgChange: true,
        pollingInterval: 5000,
    };

    const {
        data: readMonitorRunResultGroupedByMonitorIdData,
        isLoading: readMonitorRunResultGroupedByMonitorIdIsLoading,
        isFetching: readMonitorRunResultGroupedByMonitorIdIsFetching,   
    } = useReadMonitorRunResultGroupedByMonitorIdQuery(
        readMonitorRunResultByMonitorIdQueryParams,
        readMonitorRunResultByMonitorIdQueryOptions
    );

    const useReadUserSettingsQueryOptions = {
        skip: skip,
    }

    const {
        data: readUserSettingsData,
        isLoading: readUserSettingsIsLoading,
        isFetching: readUserSettingsIsFetching,
        error: readUserSettingsError
    } = useReadUserSettingsQuery(useReadUserSettingsQueryOptions);


    const handleCalendarCloseDialog = () => {
        setOpenDayDialog(false);
    }

    const isSameDay = (a: DateTime, b: DateTime): boolean => {
        return a.hasSame(b, "day") && a.hasSame(b, "month") && a.hasSame(b, "year");
    };

    const isLessDay = (a: DateTime, b: DateTime): boolean => {
        return (a.startOf("year") <= b.startOf("year")
            && a.startOf("month") <= b.startOf("month")
            && a.startOf("day") < b.startOf("day"))
    };


    const handleSelectedMonitorLaunchTaskChange = ( params: any) => {
        if (typeof onSelectedMonitorLaunchTaskChange === "function") {
            onSelectedMonitorLaunchTaskChange(params);
        }
    }

    const handleClickDay = ({ event, monitorLaunchTask }: any) => {
        if (monitorId === undefined) {
            return;
        }

        setSelectedMonitorLaunchTasksId([
            monitorLaunchTask.id
        ]);

        handleSelectedMonitorLaunchTaskChange({ monitorLaunchTask });
    }

    const customDay = (props: PickersDayProps<DateTime>) => {
        const updatedProps = { ...props, selected: false }
        const classes: any = [];
        const matchedStyles: any = {};

        matchedStyles['&.MuiPickersDay--past-day'] = {
            backgroundColor: '#d4d4d4!important',
            color: '#FFF'
        };

        const isLessDayResult = isLessDay(props.day, DateTime.now())
        if (isLessDayResult) {
            classes.push('MuiPickersDay--past-day');
        }


        if (readMonitorRunResultGroupedByMonitorIdIsLoading || userSettings === undefined || readMonitorRunResultGroupedByMonitorIdData === undefined) {
            return <PickersDay
                className={classes.join(' ')}
                sx={{ ...matchedStyles }}
                {...updatedProps}
            />;
        }

        const foundGroupedByMonitorIdDataRow = readMonitorRunResultGroupedByMonitorIdData.list
            .find((groupedByMonitorIdDataRow: any) => {
                return isSameDay(DateTime.fromISO(groupedByMonitorIdDataRow.checkinUtc), props.day);
            })


        const recommendations: any = [];

        if (foundGroupedByMonitorIdDataRow
            && monitorRunsResultDetailCard !== undefined) {

            if (foundGroupedByMonitorIdDataRow.recDir === 1) {
                classes.push('MuiPickersDay--error');
            } else if (foundGroupedByMonitorIdDataRow.recDir === -1) {
                classes.push('MuiPickersDay--error');
            } else {
                classes.push('MuiPickersDay--ok');
            }

            if (selectedMonitorLaunchTask !== undefined) {
                if (selectedMonitorLaunchTask.monitorRunsResult.id === foundGroupedByMonitorIdDataRow.id) {
                    classes.push('MuiPickersDay--selected');
                }
            }

        } else {
            matchedStyles['color'] = '#83686899';

        }

        matchedStyles['margin'] = `2px`;


        return (
            <PickersDay
                    className={classes.join(' ')}
                    onFocus={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        event.currentTarget.blur();
                        }
                    }
                    onFocusCapture={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        event.currentTarget.blur();
                    }
                    }
                    onClick={
                    (event: any) => {
                        if (foundGroupedByMonitorIdDataRow !== undefined) {
                            setSelectedDayData({
                                recommendations,
                                monitor: monitorRunsResultDetailCard,
                                monitorRunsResult: foundGroupedByMonitorIdDataRow
                            });

                            handleClickDay({event, monitorLaunchTask: foundGroupedByMonitorIdDataRow });
                        }
                    }
                    }
                    {...updatedProps} sx={{ ...matchedStyles }} />
   
        );
    };

    useEffect(() => {
        if (readUserSettingsError !== undefined
            && 'data' in readUserSettingsError
            && readUserSettingsError.status === 404
            && readUserSettingsIsFetching === false) {
            setHasUserSettings(false);
            setUserSettings(createBlankUserSettings());
        } else {
            setHasUserSettings(true)
            setUserSettings(readUserSettingsData)
        }
    }, [readUserSettingsIsFetching, readUserSettingsError]);

    useEffect(() => {
        if (readMonitorRunResultGroupedByMonitorIdIsFetching === false) {
            setShowLoader(false);
        }         
    }, [readMonitorRunResultGroupedByMonitorIdIsFetching]);


    useEffect(() => {
        setSkip(monitorId === undefined || monitorId === -1);
        setSelectedMonitorLaunchTasksId([]);
        //handleSelectedMonitorLaunchTaskChange(undefined);
        setShowLoader(true);
    }, [monitorId]);


    const [isStandaloneforAndroid, setIsStandaloneforAndroid] = useState<boolean>(window.matchMedia('(display-mode: standalone)').matches);
    // @ts-ignore
    const [isStandaloneforIOS, setIsStandaloneforIOS] = useState<boolean>(window.navigator.standalone !== undefined && window.navigator.standalone === true);

    window.addEventListener("resize", () => {
        setIsStandaloneforAndroid(window.matchMedia('(display-mode: standalone)').matches);
        // @ts-ignore
        setIsStandaloneforIOS(window.navigator.standalone !== undefined && window.navigator.standalone === true)
    });

    const renderInvintationToInstall = () => {
        if (isStandaloneforIOS || isStandaloneforAndroid) {
            return ('');
        }

        return (
            <InvitationToInstallApplication></InvitationToInstallApplication>
        )
    }

    return (
        <>
            <Box sx={{
                flexDirection: {
                    xs: 'column',
                    md: 'row'
                },
                display: 'grid',
                gridTemplateColumns: {
                    md: '2fr 1fr'
                },
                gridTemplateRows: {
                    xs: 'max-content',
                    md: 'none'
                },
                alignItems: {
                    xs: 'stretch',
                    md: 'stretch'
                }
            }}>

                
                <div>
                    <Calendar      
                        loading={showLoader || readMonitorRunResultGroupedByMonitorIdData === undefined}
                            slots={{
                                calendarHeader: () => (null),
                                day: customDay,
                            }}
                    />
                </div>
                {
                    renderInvintationToInstall()
                }
               

            </Box>
            <DayDialog
                keepMounted
                open={openDayDialog}
                value={selectedDayData}
                onClose={handleCalendarCloseDialog}
                fullScreen
                loading={readMonitorRunResultGroupedByMonitorIdIsFetching || readMonitorRunResultGroupedByMonitorIdData===undefined}
            />
        </>
    )
}