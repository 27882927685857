import * as React from 'react';
import { useState, useEffect, useRef} from 'react';
import { DataGrid, GridCellModes, GridCellModesModel, GridColDef, GridColTypeDef, GridRenderEditCellParams, GridRowModes, GridRowModesModel, GridValueGetterParams, useGridApiContext } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import { Link } from 'react-router-dom';
import { useDeleteMonitorMutation } from 'services/monitor';
import { useReadMonitorsQuery } from 'services/monitor';
import { useReadPlatformsQuery } from 'services/platform';
import { ConfirmationDialogForRemovingMonitor } from 'components/ConfirmationDialogForRemovingMonitor';
import { MonitorListToMonitorViewModelList } from 'components/Adapters/Monitor';
import Switch from '@mui/material/Switch';
import { updateMonitorFilter } from 'domain/monitor';
import type { Monitor, MonitorFilterItem, MonitorViewModel } from 'shared/types';
import { useUpdateMonitorMutation } from 'services/monitor';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Popper from '@mui/material/Popper';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { ListItemButton } from '@mui/material';
import { ListItemIcon } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';

type MonitorListTableProps = {

};

const recalculateMonitor = async (monitor: Monitor, name: string, value: any): Promise<Monitor> => {
    const monitorFilterItem: MonitorFilterItem = {
        name: name,
        value: value
    };

    const newMonitor: Monitor = updateMonitorFilter({ monitor: monitor, monitorFilterItem });
    return newMonitor;
} 



function CustomEditComponent(props: GridRenderEditCellParams) {
    const { id, value, field, hasFocus } = props;
    const apiRef = useGridApiContext();
    const ref = useRef<HTMLButtonElement | null>(null);
    const [updateMonitorMutation] = useUpdateMonitorMutation();

    const handleValueChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.checked;
        apiRef.current.setEditCellValue({ id, field, value: newValue });     
        const row = apiRef.current.getRow(id);

        if (row !== null) {  
            const newMonitor: Monitor = await recalculateMonitor(row, field, newValue);
            const updateMonitorByIdParams: any = {
                monitor: newMonitor
            }
            await updateMonitorMutation(updateMonitorByIdParams);
        }
    };

    return <Switch ref={ref} checked={value} onChange={handleValueChange} color="success" />;
}


export const MonitorListTable = ({ }: MonitorListTableProps): JSX.Element => {
    const [deleteMonitor] = useDeleteMonitorMutation();
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });
    const [open, setOpen] = useState<boolean>(false);
    const anchorRef = useRef<HTMLButtonElement>(null);
    const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deletedMonitor, setDeletedMonitor] = useState<Monitor | null>(null);
    const [menuMonitor, setMenuMonitor] = useState<Monitor | null>(null)
    const [monitors, setMonitors] = useState<MonitorViewModel[]>([])
    const [monitorsCount, setMonitorsCount] = useState<number>(0)

    const { data: readMonitorsQueryData, isLoading: ReadMonitorsQueryIsLoading } = useReadMonitorsQuery({
        pagination: paginationModel
    });

    const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({});


    const enableEditor: GridColTypeDef = {
        type: 'boolean',
        renderEditCell: (params) => <CustomEditComponent {...params} />,
    };

    const handleToggle = (params: any) => {
        const { event, value } = params;

        setAnchorElement(event.currentTarget);
        setMenuMonitor(value.row);
        setOpen((prevOpen) => !prevOpen);
    }

    const handleClose = (event: Event | React.SyntheticEvent) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpen(false);
    };

    const columns: GridColDef[] = [
        {
            field: 'title', headerName: 'Title', width: 100,
            renderCell: (value) => {     
                return (
                    <Link to={`/spy/${value.row.id}/monitorrun`}>
                        <Box className={'MuiDataGrid-cellContent'}  sx={{

                        }}>{value.row.title}</Box>
                    </Link>
                )
            }
            //flex: 1,
        },
        {
            field: 'description', headerName: 'Description', width: 120,
           // flex: 1,
        },
        {
            field: 'locations',
            headerName: 'Locations',
            width: 150,
            //flex: 1,
        },
        {
            field: 'collectNearByLocations',
            renderHeader: () => (<Box className={`MuiDataGrid-columnHeaderTitle`} sx={{ display: 'flex', flexDirection: 'column' }}><span>Near By</span><span>Locations</span></Box>),
            width: 110,
            type: 'boolean',
            //flex: 1,
        },
        {
            field: 'PlatformId',
            headerName: 'Platform',
            width: 120,
            valueGetter: (params: GridValueGetterParams) => {
                return params.row.platform.name;
            }
        },
        {
            field: 'bedroomsLabel',
            headerName: 'Bedrooms',
            type: 'string',
            width: 110,
            minWidth: 110,
        },
        {
            field: 'bedsLabel',
            headerName: 'Beds',
            type: 'string',
            width: 90,
            minWidth: 90,
        },
        {
            field: 'petsLabel',
            headerName: 'Pets',
            type: 'string',
            width: 90,
            minWidth: 90,
        },
        {
            field: 'period',
            headerName: 'Period',
            type: 'string',
            width: 140,
            minWidth: 140,
        },
        {
            field: 'priceForComparison',
            headerName: '',
            renderHeader: () => (<Box className={`MuiDataGrid-columnHeaderTitle`} sx={{ display: 'flex', flexDirection: 'column' }}><span>Total price you charge</span><span>(including cleaning fee)</span></Box>),
            width: 260,
            minWidth: 260,
            valueGetter: (params: GridValueGetterParams) => {
                return `$${params.row.priceForComparison}`;
            }
        },
        {
            field: 'enabled',
            headerName: 'Enabled',
            editable: true,
            width: 160,
            ...enableEditor
        },
        {
            field: 'actions',
            headerName: 'Actions',
            sortable: false,
            minWidth: 140,
            flex: 1,
            renderCell: (value) => {
                return (
                    <Stack direction="row" spacing={2}>
                        {/*
                        <Button
                            color="error"
                            size="small"
                            variant="contained"
                            startIcon={<DeleteIcon />}
                            onClick={onDeleteHandler}
                            sx={{
                                minWidth: 110,
                            }}
                        >
                            Delete
                        </Button >
                        <Link to={`/monitor/${value.row.id}`}>
                            <Button
                                color="primary"
                                size="small"
                                variant="contained"
                                startIcon={<EditIcon />}
                                sx={{
                                    minWidth: 110,
                                }}
                            >
                                Edit
                            </Button >
                        </Link>
                        <Link to={`/monitor/${value.row.id}/monitorrun`}>
                            <Button
                                color="success"
                                size="small"
                                variant="contained"
                                startIcon={<AnalyticsIcon />}
                                sx={{
                                    minWidth: 110,
                                }}
                            >
                                Results
                            </Button >
                        </Link>
                        */}

                        <IconButton
                            size="large"
                            edge="start"
                            color="default"
                            aria-label="menu"
                            sx={{ mr: 0 }}
                            onClick={(event,) => handleToggle({ event, value })}
                        >
                            <MoreVertIcon />
                        </IconButton>
                    </Stack>
                )
            }
        }
    ];

    const confirmDeleteMonitorHandler = async () => {
        setOpenDeleteDialog(false);

        if (menuMonitor && menuMonitor.id !== undefined) {
            await deleteMonitor({ monitorId: menuMonitor.id });
        }    
    }

    const cancelDeleteMonitorHandler = () => {
        setOpenDeleteDialog(false);
    }

    const onMonitorViewModelListChangedHandler = (event: any) => {
        setMonitors(event.value);
        setMonitorsCount(event.value.length);
    }

    const handleDeleteMonitor = () => {
        setOpenDeleteDialog(true);
    }

    useEffect(() => {
        if (monitors !== undefined) {
            const cellModel: GridCellModesModel = monitors.reduce((accumulator: any, row: Monitor, index, arr) => {
                if (row.id !== undefined) {
                    accumulator[row.id.toString()] = {
                        enabled: {
                            mode: GridCellModes.Edit,
                        },    
                    };
                }

                return accumulator;
            }, {})

            setCellModesModel(cellModel);
        }
    }, [monitors, monitorsCount]);



    return (     
        <>
            <MonitorListToMonitorViewModelList
                monitorList={readMonitorsQueryData?.list}
                onMonitorViewModelListChanged={onMonitorViewModelListChangedHandler} 
            />
            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr' }}>
                <DataGrid
                    rows={monitors}
                    loading={ReadMonitorsQueryIsLoading}
                    cellModesModel={cellModesModel}
                    rowCount={monitorsCount}
                    columns={columns}
                    pageSizeOptions={[5]}
                    paginationModel={paginationModel}
                    paginationMode="server"
                    editMode="cell"
                    disableRowSelectionOnClick={true}      
                    onPaginationModelChange={setPaginationModel}
                    sx={{
                        minHeight: 180,
                        maxHeight: 400,
                        '& .MuiDataGrid-columnHeaderTitle': {
                            fontSize: {
                                xs: '12px',
                                sm: '14px',
                                md: '16px',
                            },
                            fontWeight: '700',
                            lineHeight: 'normal'
                        },
                        '& .MuiDataGrid-cellContent': {
                            fontSize: {
                                xs: '14px',
                                sm: '16px',
                                md: '18px',
                            }
                        },
                    }}
                />
                <ConfirmationDialogForRemovingMonitor
                    id="confirmation-dialog-for-removing-Monitor"
                    keepMounted
                    open={openDeleteDialog}
                    onConfirm={confirmDeleteMonitorHandler}
                    onCancel={cancelDeleteMonitorHandler}
                    monitor={deletedMonitor}
                />
                <Popper
                    open={open}
                    anchorEl={anchorElement}
                    role={undefined}
                    placement="bottom-start"
                    transition
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin:
                                    placement === 'bottom-start' ? 'left top' : 'left bottom',
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <List component="nav">
                                        <ListItemButton onClick={handleDeleteMonitor}>
                                            <ListItemIcon>
                                                <DeleteIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Delete" />
                                        </ListItemButton>
                                        <ListItemButton component={Link} to={`/spy/${menuMonitor?.id}`}>
                                            <ListItemIcon>
                                                <EditIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Edit" />
                                        </ListItemButton>
                                        <ListItemButton component={Link} to={`/spy/${menuMonitor?.id}/monitorrun`}>
                                            <ListItemIcon>
                                                <AnalyticsIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Results" />
                                        </ListItemButton>
                                    </List>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Box>
        </>
      
    );
}